.App {
  text-align: center;
  background-image: url("./images/deez.gif");
  background-repeat: repeat;
}

.frame { 
  width: 100vw;
  height: 100vh;
  position: relative;
}

.jus {
  border-radius: 30%;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
